<script>
export default {
  name: "contentPage",
  data() {
    return {
      order: "horizontal",
    }
  },
  props: {
    contentStr: {
      type: String,
      required:true
    }
  },
  methods: {
    onclick(){
      this.$emit('getContent')
    }
  }
}
</script>

<template>
  <div class="content-container">
    <div class="radio">
      <el-radio-group v-model="order" size="small">
        <el-radio label="horizontal" border>横排</el-radio>
        <el-radio label="vertical" border>竖排</el-radio>
      </el-radio-group>
    </div>
    <div class="temp">
      <p
          :class="[order === 'horizontal' ? 'paragraph1' : 'paragraph']"
          v-html="contentStr"
      />
    </div>
    <div class="tool">
      <el-button type="primary" plain @click="onclick"
      >重新生成段落
      </el-button
      >
    </div>
  </div>
</template>

<style scoped lang="scss">
.content-container {
  width: 100%;
  height: 100%;

  .temp {
    width: 100%;
    overflow: auto;
    height: calc(100% - 150px);
  }

  .radio {
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    .el-radio {
      margin-right: 0px;
    }
  }
  .paragraph {
    margin: 0;
    font-family: 宋体;
    width: 100%;
    height: 100%;
    writing-mode: tb-rl;
    font-size: 20px;
    letter-spacing: 2px;
    white-space: pre-wrap;
    overflow: auto;
  }

  .paragraph1 {
    margin: 0;
    font-family: 宋体;
    width: 100%;
    height: 100%;
    writing-mode: lr-tb;
    font-size: 20px;
    padding: 10px;
    line-height: 33px;
    letter-spacing: 2px;
    white-space: pre-wrap;
  }

  .tool {
    height: 50px;
    border-top: 1px solid #eee;
    text-align: end;
    line-height: 50px;
  }
}
</style>
