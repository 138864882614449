<template>
  <div class="container" @click="fontId = ''">
    <div class="header">
      <span>校对模式：</span>
      <i/>
      <el-radio-group v-model="proofreadMode" size="small">
        <el-radio label="about" border>左右</el-radio>
        <el-radio label="bilayer" border>双层</el-radio>
      </el-radio-group>
      <template v-if="proofreadMode === 'bilayer'">
        <i/>
        <div class="checkbox">
          <el-checkbox v-model="hiddenBack">隐藏背景</el-checkbox>
          <el-checkbox v-model="hiddenProofread">隐藏校对层</el-checkbox>
        </div>
        <div class="slider-box">
          <p>左右:</p>
          <!-- 滑块 -->
          <div v-show="xSlider" class="slider">
            <el-slider
                v-model="xValue"
                style="margin-top: -10px"
                :min="-200"
                :max="200"
            />
          </div>
          <!-- 滑块2 -->
          <div v-show="ySlider" class="slider">
            <el-slider
                v-model="yValue"
                style="margin-top: -10px"
                :min="-200"
                :max="200"
            />
          </div>
          <el-input
              v-model="xValue"
              style="width: 70px; margin-left: 5px"
              @blur="xSlider = false"
              @focus="xSlider = true"
          />

          <p>上下:</p>
          <el-input
              v-model="yValue"
              style="width: 70px; margin-left: 5px"
              @blur="ySlider = false"
              @focus="ySlider = true"
          />
        </div>
      </template>
      <i/>
      <el-button-group>
        <el-tooltip class="item" effect="dark" content="放大" placement="top">
          <el-button
              size="small"
              icon="el-icon-circle-plus-outline"
              @click="amplification(0.03)"
          />
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="缩小" placement="top">
          <el-button
              size="small"
              icon="el-icon-remove-outline"
              @click="amplification(-0.03)"
          />
        </el-tooltip>
        <el-tooltip
            class="item"
            effect="dark"
            content="删除所选文字"
            placement="top"
        >
          <el-button size="small" icon="el-icon-delete" @click="deleteSelect"/>
        </el-tooltip>
      </el-button-group>
    </div>
    <div class="box-card">
      <div ref="proofread" class="proofread">
        <div
            ref="left"
            class="left"
            :style="{ width: proofreadMode === 'about' ? '50%' : 0 }"
            @mousedown.self="down"
        >
          <div
              ref="imgBox"
              class="img-box"
              :style="{ transform: `translate(0, 0) scale(${zoomscale}` }"
          >
            <div
                v-show="filename"
                ref="img"
                class="img"
                :style="{
                backgroundImage: `url('${baseUrl}/api/fileinfo/image/GetGPTMessageCommentImageGetOriImage?filename=${filename}')`,
                width: pageWidth + 'px',
                height: pageHeight + 'px',
              }"
                @mousedown.self="down"
            >
              <div ref="hoverBox" class="hoverBox"/>
              <div v-show="copyFontId" ref="copyBox" class="copyBox"/>
            </div>
          </div>
        </div>
        <div
            ref="right"
            class="right"
            :style="{ width: proofreadMode === 'about' ? '50%' : '100%' }"
            @mousedown.self="down"
        >
          <div
              ref="fontBox"
              class="font-box"
              :style="{ transform: `translate(0, 0) scale(${zoomscale}` }"
          >
            <div
                ref="font"
                class="font"
                :style="{
                width: pageWidth + 'px',
                height: pageHeight + 'px',
                backgroundImage:
                  proofreadMode === 'bilayer' && !hiddenBack
                    ? `url('${baseUrl}/api/fileinfo/image/GetGPTMessageCommentImageGetOriImage?filename=${filename}')`
                    : '',
              }"
                @mousedown.self="down"
                @click.stop="onWordClick"
            >
              <div
                  v-show="selectionBoxShow"
                  ref="selectionBox"
                  class="selection-box"
              >
                <div class="right-bottom"/>
              </div>
              <div v-show="copyFontId" ref="copyMask" class="copyMask"/>
              <div v-if="!hiddenProofread" ref="leftmask" class="leftmask">
                <span
                    v-for="(item, index) in chars"
                    ref="word"
                    :key="item._id"
                    :data-item="JSON.stringify(item)"
                    :data-index="index"
                    :style="{
                    left: convertRect(item.rcInImage)[0].x + 'px',
                    top: convertRect(item.rcInImage)[0].y + 'px',
                    fontFamily: `KaiTi`,
                    fontSize: convertRect(item.rcInImage)[0].height + 'px',
                    width: convertRect(item.rcInImage)[0].width + 'px',
                    height: convertRect(item.rcInImage)[0].height + 'px',
                    background: fontId === item._id ? 'red' : '',
                  }"
                    @mouseover="onWordMouseover(index, $event)"
                    @mouseout="onWordMouseout"
                >
                  {{ item.code }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- 相似字列表 -->
        <div
            v-show="fontId === fontList._id"
            ref="fontListBox"
            class="box-right-font-window"
        >
          <el-input
              v-model="unfold"
              style="width: 160px"
              placeholder="请输入内容"
              @click.native.stop
              @keydown.native.enter="
              submitFont(unfold, fontList._id, fontList.rcInImage)
            "
          />
          <el-button
              style="
              position: absolute;
              right: -80px;
              margin-top: 0px;
              width: 80px;
            "
              type="primary"
              size="mini"
              @click.native.stop="
              submitFont(unfold, fontList._id, fontList.rcInImage)
            "
          >提交
          </el-button>
          <el-button
              style="
              position: absolute;
              right: -80px;
              margin-top: 30px;
              width: 80px;
            "
              type="danger"
              size="mini"
              @click.native.stop="onDelete(fontList._id)"
          >删除
          </el-button>
          <el-button
              style="
              position: absolute;
              right: -80px;
              margin-top: 60px;
              width: 80px;
            "
              size="mini"
              @click.stop="fontId = ''"
          >取消
          </el-button>
          <div class="window-ctn">
            <div class="window-ctn-list">
              <div
                  v-for="(smil, index) in similar"
                  :key="index"
                  class="window-list-box"
                  @click.stop="submitFont(smil, fontList._id, fontList.rcInImage)"
              >
                {{ smil }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <el-tabs v-model="activeName" type="card">
          <el-tab-pane label="元数据" name="myMetadata"></el-tab-pane>
          <el-tab-pane label="段落" name="myContent"></el-tab-pane>
        </el-tabs>
        <component :is="activeName" :contentStr="content" @getContent="onclick" @saveMetadata="saveMetadata"
                   :gptMessageDocumentInfoDto="gptMessageDocumentInfoDto"></component>
      </div>
      <div v-if="filename">
        <el-image
            v-show="false"
            :src="`${baseUrl}/api/fileinfo/image/GetGPTMessageCommentImageGetOriImage?filename=${filename}`"
            @load="loadImg"
        />
      </div>
      <el-dialog
          v-dialogdrag
          title="重新生成段落"
          :visible.sync="isParagraphShow"
          width="30%"
          @close="cancelParagraph"
      >
        <div class="dialog-box">
          <div class="item">
            <span>是否保留空格</span>
            <el-radio-group v-model="holdSpace" size="small">
              <el-radio :label="true" border>是</el-radio>
              <el-radio :label="false" border>否</el-radio>
            </el-radio-group>
          </div>
          <div class="item">
            <span>是否自动分析书写方向</span>
            <el-radio-group v-model="autoWriteModel" size="small">
              <el-radio :label="true" border>是</el-radio>
              <el-radio :label="false" border>否</el-radio>
            </el-radio-group>
          </div>
          <div class="item">
            <span>文字是否横向书写</span>
            <el-radio-group v-model="landscape" size="small">
              <el-radio :label="true" border>是</el-radio>
              <el-radio :label="false" border>否</el-radio>
            </el-radio-group>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelParagraph">取 消</el-button>
          <el-button type="primary" @click="getParagraph">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getOcrInfo,
  updateChar,
  deleteWord,
  pasteWord,
  batchRemoveWords,
  generateParagraph,
  updateDocumentInfo
} from "../../api/proofread";
import myContent from "./components/content.vue";
import myMetadata from './components/metadata.vue'

function throttle(fn, interval) {
  const _self = fn; // 保存需要被延迟执行的函数引用
  let timer; // 定时器
  let firstTime = true; // 是否是第一次调用
  return function () {
    const args = arguments;
    const _me = this;
    if (firstTime) {
      // 如果是第一次调用不需要延迟
      _self.apply(_me, args); // 执行fn函数并且修正此函数中this所运行的上下文指向
      return (firstTime = false);
    }
    if (timer) {
      // 如果定时器还在，说明前一次延迟执行还没有完成
      return false;
    }
    timer = setTimeout(function () {
      // 延迟一段时间执行
      clearTimeout(timer);
      timer = null;
      _self.apply(_me, args); // 执行fn函数并且修正此函数中this所运行的上下文指向
    }, interval || 500);
  };
}

export default {
  components: {myContent, myMetadata},
  data() {
    return {
      gptMessageDocumentInfoDto: {},
      activeName: 'myMetadata',
      landscape: true, // 横向书写
      autoWriteModel: true, // 自动拆分
      holdSpace: true, // 保留空格
      proofreadMode: "about",
      hiddenBack: false,
      hiddenProofread: false,
      selectionBoxShow: false,
      isParagraphShow: false,
      xValue: 0,
      yValue: 0,
      xSlider: false,
      ySlider: false,
      proofreadId: "",
      sourceID: "",
      filename: "",
      pageWidth: 0,
      pageHeight: 0,
      chars: [],
      similar: [],
      hoverIndex: -1,
      fontId: "",
      fontList: {},
      unfold: "",
      copyFontId: "",
      zoomscale: 0.310719,
      rectCopyMask: {},
      startPosition: {},
      deleteIdList: [],
      // 初始放大缩小比例值
      ZoomInOnLeft: 0,
      ZoomInOnTop: 0,
      // 图片拖拽距离存储
      distanceX: 0,
      distanceY: 0,
      content: "",
    };
  },
  computed: {
    convertRect() {
      return function (info) {
        var rect = [{x: 0, y: 0, width: 0, height: 0}];

        if (!info) {
          return rect;
        }
        var infos = info.split(",");
        if (infos.length === 4) {
          rect[0].x = parseFloat(infos[0]);
          rect[0].y = parseFloat(infos[1]);
          rect[0].width = parseFloat(infos[2]) - rect[0].x;
          rect[0].height = parseFloat(infos[3]) - rect[0].y;
        }
        return rect;
      };
    },
    baseUrl() {
      const {protocol, hostname, port} = window.location;
      return `${protocol}//${hostname}${port ? `:${port}` : ""}`;
    },
  },
  watch: {
    // 滑块左右
    xValue(val) {
      const leftmask = this.$refs.leftmask;
      leftmask.style.left = `${val}px`;
    },
    yValue(val) {
      const leftmask = this.$refs.leftmask;
      leftmask.style.top = `${val}px`;
    },
    proofreadMode() {
      this.yValue = 0;
      this.xSlider = 0;
      this.hiddenBack = false;
      this.hiddenProofread = false;
    },
  },
  created() {
    this.proofreadId = this.$route.params.id;
    this.getProofreadInfo();
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyboardEvent);
  },
  beforeDestroy() {
    // this.$store.commit("user/SET_PREVIEW", "");
    window.removeEventListener("keydown", this.handleKeyboardEvent);
  },
  methods: {
    // 保存元数据
    async saveMetadata() {
      try {
        const res = await updateDocumentInfo(this.sourceID, this.gptMessageDocumentInfoDto)
        this.gptMessageDocumentInfoDto = res.documentInfos
        this.$message.success('操作成功')
      } catch (e) {
        this.$message.error('操作失败')
      }
    },
    // 打开生成段落弹窗
    onclick() {
      this.isParagraphShow = true;
    },
    cancelParagraph() {
      this.isParagraphShow = false;
    },
    async getParagraph() {
      try {
        const res = await generateParagraph({
          sourceID: this.sourceID,
          ocrInfoID: this.proofreadId,
          holdSpace: this.holdSpace,
          autoWriteModel: this.autoWriteModel,
          landscape: this.landscape,
          fdCharDtos: [],
        });
        this.content = res.content;
        this.$message.success("操作成功！");
      } catch (error) {
        console.log(error);
        this.$message.error("操作失败！");
      } finally {
        this.cancelParagraph();
      }
    },
    // 批量删除
    async deleteSelect() {
      if (!this.deleteIdList.length) {
        return this.$message.info("请先选择需要删除的文字！");
      }
      try {
        await batchRemoveWords({
          ocrInfoID: this.proofreadId,
          chardIds: this.deleteIdList,
        });
        await this.getProofreadInfo();
        this.$message.success("操作成功！");
      } catch (error) {
        console.log(error);
        this.$message.error("操作失败！");
      } finally {
        this.deleteIdList = [];
      }
    },
    // 缩放
    amplification(i) {
      if (this.zoomscale + i < 0) return;
      this.zoomscale = this.zoomscale + i;
      if (i < 0) {
        this.$refs.imgBox.style.top =
            parseFloat(this.$refs.imgBox.style.top) === 0
                ? 0
                : parseFloat(this.$refs.imgBox.style.top) * this.zoomscale + "px";
        this.$refs.imgBox.style.left =
            parseFloat(this.$refs.imgBox.style.left) === 0
                ? 0
                : parseFloat(this.$refs.imgBox.style.left) * this.zoomscale + "px";

        this.$refs.fontBox.style.top =
            parseFloat(this.$refs.fontBox.style.top) === 0
                ? 0
                : parseFloat(this.$refs.fontBox.style.top) * this.zoomscale + "px";
        this.$refs.fontBox.style.left =
            parseFloat(this.$refs.fontBox.style.left) === 0
                ? 0
                : parseFloat(this.$refs.fontBox.style.left) * this.zoomscale + "px";
      }
    },
    // 修改
    async submitFont(code, id, rcInImage) {
      if (!code) return this.$message.info("不能为空");
      try {
        await updateChar({
          id,
          rcInImage,
          code,
          ocrInfoID: this.proofreadId,
          sourceID: this.sourceID,
        });
        await this.getProofreadInfo();
        this.$message.success("操作成功！");
      } catch (error) {
        console.log(error);
        this.$message.error("操作失败！");
      } finally {
        this.fontId = "";
      }
    },
    // 删除
    async onDelete(id) {
      try {
        await deleteWord({
          Id: id,
          OCRInfoID: this.proofreadId,
        });
        await this.getProofreadInfo();
        this.$message.success("操作成功！");
      } catch (error) {
        console.log(error);
        this.$message.error("操作失败！");
      } finally {
        this.fontId = "";
      }
    },
    async handleKeyboardEvent(e) {
      // 取消复制
      if (e.key === "Escape") {
        this.cancelCopy();
      }
      // 复制
      if (this.fontId && e.code === "KeyC" && e.ctrlKey) {
        this.copyFontId = this.fontId;
        this.$nextTick(() => {
          this.rectCopyMask = this.$refs.copyMask.getBoundingClientRect();
        });
        this.$refs.copyMask.addEventListener("mousemove", this.copyMaskMove, {
          capture: true,
        });
        this.fontId = "";
      }
      // 粘贴
      if (
          this.copyFontId &&
          e.code === "KeyV" &&
          e.ctrlKey &&
          e.target.nodeName === "BODY"
      ) {
        try {
          const xOrYList =
              this.$refs.copyBox.style.transform.match(/-?\d+(\.\d+)?/g);
          const width = parseFloat(this.$refs.copyBox.style.width);
          const height = parseFloat(this.$refs.copyBox.style.height);
          // 左上，右下 两点
          const rcInImage = `${parseFloat(xOrYList[0])},${parseFloat(
              xOrYList[1]
          )},${parseFloat(xOrYList[0]) + width},${
              parseFloat(xOrYList[1]) + height
          }`;
          await pasteWord({
            copyCharId: this.copyFontId,
            ocrInfoID: this.proofreadId,
            intoOCRInfoID: this.proofreadId,
            rcInImage,
          });
          await this.getProofreadInfo();
          this.$message.success("操作成功！");
        } catch (error) {
          console.log(error);
          this.$message.error("操作失败！");
        }
      }
    },
    // 取消复制
    cancelCopy() {
      this.copyFontId = "";
    },
    copyMaskMove: throttle(function (e) {
      if (e.offsetX && e.offsetY) {
        this.updatePosition(e.offsetX, e.offsetY);
      } else {
        const x = (e.clientX - this.rectCopyMask.left) / this.zoomscale;
        const y = (e.clientY - this.rectCopyMask.top) / this.zoomscale;
        this.updatePosition(x, y);
      }
    }, 16),
    updatePosition(x, y) {
      this.$refs.copyBox.style.transform = `translate(${
          x - parseFloat(this.$refs.copyBox.style.width) / 2
      }px, ${y - parseFloat(this.$refs.copyBox.style.height) / 2}px)`;
    },
    frameSelection(event) {
      this.selectionBoxShow = true;
      this.$refs.right.addEventListener("mousemove", move);
      this.$refs.right.addEventListener("mouseup", (e) => {
        this.InRegionWords();
        this.selectionBoxShow = false;
        this.$refs.right.removeEventListener("mousemove", move);
      });
      // 鼠标初始位置
      this.startPosition = {
        x: event.offsetX,
        y: event.offsetY,
      };
      const clientX = event.clientX;
      const clientY = event.clientY;
      const selectNode = this.$refs.selectionBox;
      selectNode.style.left = this.startPosition.x + "px";
      selectNode.style.top = this.startPosition.y + "px";
      selectNode.style.width = 0;
      selectNode.style.height = 0;
      const that = this;

      function move(e) {
        if (e.ctrlKey) {
          selectNode.style.width =
              (e.clientX - clientX) / that.zoomscale + "px";
          selectNode.style.height =
              (e.clientY - clientY) / that.zoomscale + "px";
        }
      }
    },
    InRegionWords() {
      const wordsList = this.$refs.word;
      const selectionBox = this.$refs.selectionBox;
      const maxX = parseFloat(selectionBox.style.width) + this.startPosition.x;
      const maxY = parseFloat(selectionBox.style.height) + this.startPosition.y;
      this.deleteIdList = [];
      wordsList.forEach((v) => {
        if (
            v.offsetLeft > this.startPosition.x - 50 &&
            v.offsetLeft < maxX &&
            v.offsetTop > this.startPosition.y - 70 &&
            v.offsetTop < maxY
        ) {
          v.style.background = "rgba(120, 181, 207, 0.3)";
          v.style.color = "red";
          const item = JSON.parse(v.dataset.item);
          this.deleteIdList.push(item._id);
        } else {
          v.style.background = "";
          v.style.color = "#337ab7";
        }
      });
    },
    onWordMouseout(e) {
      this.$refs.hoverBox.style.display = "none";
    },
    onWordMouseover(index, e) {
      this.hoverIndex = index;
      if (this.proofreadMode === "about" && this.$refs.hoverBox) {
        this.$refs.hoverBox.style.display = "block";
        this.$refs.hoverBox.style.left = e.target.style.left;
        this.$refs.hoverBox.style.top = e.target.style.top;
        if (e.target.offsetWidth && e.target.offsetHeight) {
          this.$refs.hoverBox.style.width = e.target.offsetWidth + "px";
          this.$refs.hoverBox.style.height = e.target.offsetHeight + "px";
        } else {
          this.$refs.hoverBox.style.width = e.target.style.width;
          this.$refs.hoverBox.style.height = e.target.style.height;
        }
        this.$refs.hoverBox.style.position = "absolute";
        this.$refs.hoverBox.style.border = "2px solid red";
      }
    },
    onWordClick(e) {
      if (e.target.nodeName === "SPAN") {
        const item = JSON.parse(e.target.dataset.item);
        this.fontList = item;
        this.similar = item.similar.split(",");
        this.fontId = item._id;
        const proofread = this.$refs.proofread;
        const fontListBox = this.$refs.fontListBox;
        const disX = e.clientX - proofread.offsetLeft;
        const disY = e.clientY - proofread.offsetTop;
        this.$refs.copyBox.style.width = e.target.style.width;
        this.$refs.copyBox.style.height = e.target.style.height;
        if (disY > 450) {
          fontListBox.style.left = disX + "px";
          fontListBox.style.top = disY - 250 + "px";
        } else {
          fontListBox.style.left = disX + "px";
          fontListBox.style.top = disY + "px";
        }
      }
    },
    loadImg(e) {
      // 兼容 Firefox 浏览器
      const path = e.path || (e.composedPath && e.composedPath());
      setTimeout(() => {
        this.pageWidth = path[0].naturalWidth;
        this.pageHeight = path[0].naturalHeight;
      }, 500);
    },
    async getProofreadInfo() {
      try {
        const res = await getOcrInfo(this.proofreadId);
        this.filename = res.pictureFullName;
        this.chars = res.chars;
        this.content = res.content;
        this.sourceID = res.sourceID;
        this.gptMessageDocumentInfoDto = res.gptMessageDocumentInfoDto
      } catch (error) {
        console.log(error);
      }
    },
    // 拖拽
    down(e) {
      this.fontId = "";
      if (e.ctrlKey) {
        return this.frameSelection(e);
      }
      const that = this;
      // 外层盒子宽度
      const leftBox = that.$refs.left;
      const outwidth = leftBox.offsetWidth;
      const outheight = leftBox.offsetHeight;
      // 内层盒子宽度
      const leftdiv = that.$refs.imgBox;
      const rightdiv = that.$refs.fontBox;
      const innerwidth = that.pageWidth * that.zoomscale;
      const innerheight = that.pageHeight * that.zoomscale;
      that.ZoomInOnTop = innerheight - outheight;
      that.ZoomInOnLeft = innerwidth - outwidth;
      var leftX = e.clientX;
      var topY = e.clientY;
      document.onmousemove = (e) => {
        // 鼠标按下并移动的事件
        // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置

        const left = e.clientX - leftX;
        // console.log(that.distanceX + left);
        const top = e.clientY - topY;
        // console.log(that.distanceY + top);
        if (
            that.distanceX + left <= 0 &&
            Math.abs(that.distanceX + left) <= that.ZoomInOnLeft
        ) {
          leftdiv.style.left = that.distanceX + left + "px";
          rightdiv.style.left = that.distanceX + left + "px";
        }
        if (
            that.distanceY + top <= 0 &&
            Math.abs(that.distanceY + top) <= that.ZoomInOnTop
        ) {
          leftdiv.style.top = that.distanceY + top + "px";
          rightdiv.style.top = that.distanceY + top + "px";
        }
      };
      document.onmouseup = (e) => {
        that.distanceX = leftdiv.offsetLeft;
        that.distanceY = leftdiv.offsetTop;
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100vw;
  height: 100vh;

  .header {
    background: #e4f5ff;
    height: 50px;
    padding: 0 10px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;

    .el-radio {
      margin-right: 0px;
    }

    i {
      display: inline-block;
      height: 50px;
      width: 1px;
      background: #eee;
      margin: 0 10px;
    }

    .checkbox {
      display: flex;
      flex-direction: column;
    }

    .slider-box {
      display: flex;
      align-items: center;
      position: relative;

      .slider {
        width: 200px;
        height: 18px;
        position: absolute;
        left: 0;
        z-index: 100;
        bottom: -30px;
        background: white;
        line-height: 30px;
        border-radius: 5px;
        box-shadow: 1px 3px 5px black;
      }

      ::v-deep .el-input__inner {
        height: 30px;
      }
    }
  }

  .box-card {
    padding: 5px;
    height: calc(100% - 50px);
    display: flex;

    .proofread {
      position: relative;
      width: 80%;
      display: flex;
      height: 100%;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

      .left {
        overflow: hidden;
        transition: 0.5s;
        width: 50%;
        height: 100%;
        position: relative;
        cursor: move;

        .img-box {
          position: absolute;
          top: 0px;
          left: 0px;

          .img {
            position: absolute;
            left: 0px;
            top: 0;
            transition: 0.5s;
            user-select: none;
            background-size: 200% 100%;
            background-position: center center;
            background: rgba(231, 231, 231, 0.3);

            .hoverBox {
              display: none;
            }

            .copyBox {
              width: 0;
              height: 0;
              border: 2px solid red;
              transform: translate(0, 0); /* 初始位置 */
              transition: transform 0.1s ease; /* 添加过渡效果 */
            }
          }
        }
      }

      .right {
        overflow: hidden;
        transition: 0.5s;
        width: 50%;
        height: 100%;
        background: #ddd;
        position: relative;
        cursor: move;

        .selection-box {
          border: 2px dashed rgb(137, 171, 227);
          background: rgba(120, 181, 207, 0.3);
          position: absolute;
          left: 0;
          top: 0;

          .right-bottom {
            position: absolute;
            right: -10px;
            bottom: -10px;
            background: #89abe3;
            width: 15px;
            height: 15px;
            border-radius: 100%;
            cursor: se-resize;
          }
        }

        .font-box {
          position: absolute;
          top: 0px;
          left: 0px;

          .font {
            position: absolute;
            top: 0;
            left: 0;
            transition: 0.5s;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            .leftmask {
              position: absolute;
              left: 0px;
              top: 0;
            }

            .copyMask {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 999999;
            }

            span {
              cursor: default;
              position: absolute;
              color: #337ab7;
            }

            span:hover {
              background: #a5a3a3;
            }
          }
        }
      }

      .box-right-font-window {
        position: absolute;
        z-index: 10;
      }

      .window-ctn {
        width: 160px;
        height: 230px;
        background: #337ab7;
        overflow-x: hidden;
      }

      .window-ctn-list {
        width: 160px;
        height: 230px;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        overflow: auto;
      }

      .window-list-box {
        width: 160px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        cursor: pointer;
        color: #337ab7;
      }

      .window-list-box:hover {
        background: #ddd;
        text-decoration: underline;
      }
    }

    .content {
      width: 20%;
      height: 100%;
      border: 1px solid #eee;
      margin-left: 5px;
      overflow: hidden;

      ::v-deep .el-tabs__header {
        margin: 0;
      }
    }
  }

  .dialog-box {
    .item {
      padding: 10px;

      span {
        margin-right: 10px;
      }

      .el-radio {
        margin-right: 0;
      }
    }
  }
}
</style>
