import request from '@/utils/request'

// 单页校对
export const getOcrInfo = id => {
    return request({
        method: 'GET',
        url: `/proofread/g-pTMessage/${id}/document-oCRInfo`
    })
}

// 修改文字
export const updateChar = data => {
    return request({
        method: 'PUT',
        url: `/proofread/g-pTMessage/char-info`,
        data
    })
}

// 删除文字
export const deleteWord = params => {
    return request({
        method: 'DELETE',
        url: `/proofread/g-pTMessage/har-info`,
        params
    })
}

// 粘贴字
export const pasteWord = data => {
    return request({
        method: 'POST',
        url: `/proofread/g-pTMessage/copy-word`,
        data
    })
}

// 批量删除字
export const batchRemoveWords = data => {
    return request({
        method: 'POST',
        url: `/proofread/g-pTMessage/batch-remove-words`,
        data
    })
}

// 重新生成段落
export const generateParagraph = data => {
    return request({
        method: 'POST',
        url: `/proofread/g-pTMessage/build-paragraph-content`,
        data
    })
}
// 修改元数据
export const updateDocumentInfo = (id,data) => {
    return request({
        method: 'PUT',
        url: `/userrole/g-pTMessage-comments/g-pTComment-document-info/${id}`,
        data
    })
}
