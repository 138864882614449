<script>
export default {
  name: "metadata",
  props: {
    gptMessageDocumentInfoDto: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formLabelWidth: '100px',
      documentTypeList: [
        {label: '谱牒', value: 133}
      ],
    }
  },
  methods: {
    onclick(){
      this.$emit('saveMetadata')
    }
  }
}
</script>

<template>
  <div class="metadata-container">
    <div class="temp">
      <el-form
          ref="gptMessageDocumentInfoDto"
          :model="gptMessageDocumentInfoDto"
          style="display: flex; flex-wrap: wrap;margin-top:10px"
      >
        <el-form-item label="类型" :label-width="formLabelWidth">
          <el-select v-model="gptMessageDocumentInfoDto.documentType" placeholder="请选择">
            <el-option
                v-for="item in documentTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="题名" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.title" placeholder="请输入内容"/>
        </el-form-item>
        <el-form-item label="责任者名称" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.contributor" placeholder="请输入内容"/>
        </el-form-item>
        <el-form-item label="出版地" :label-width="formLabelWidth">
          <el-input
              v-model="gptMessageDocumentInfoDto.placeOfPublication"
              placeholder="请输入内容"
          />
        </el-form-item>
        <el-form-item label="出版者名称" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.publisher" placeholder="请输入内容"/>
        </el-form-item>
        <el-form-item label="出版时间" :label-width="formLabelWidth">
          <el-input
              v-model="gptMessageDocumentInfoDto.dateOfPublication"
              placeholder="请输入内容"
          />
        </el-form-item>
        <el-form-item label="页码" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.extent" placeholder="请输入内容"/>
        </el-form-item>
        <el-form-item label="其他题名" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.alternative" placeholder="请输入内容"/>
        </el-form-item>
        <el-form-item label="责任方式" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.role" placeholder="请输入内容"/>
        </el-form-item>
        <el-form-item label="中图分类" :label-width="formLabelWidth">
          <el-input
              v-model="gptMessageDocumentInfoDto.classification"
              placeholder="请输入内容"
          />
        </el-form-item>
        <el-form-item label="主题" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.keyword" placeholder="请输入内容"/>
        </el-form-item>
        <el-form-item label="附注" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.notes" placeholder="请输入内容"/>
        </el-form-item>
        <el-form-item label="内容形式" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.contentForm" placeholder="请输入内容"/>
        </el-form-item>

        <!-- 谱牒 -->
        <template v-if="gptMessageDocumentInfoDto.documentType === 133">
          <el-form-item label="姓氏" :label-width="formLabelWidth">
            <el-input v-model="gptMessageDocumentInfoDto.surname" placeholder="请输入内容"/>
          </el-form-item>
          <el-form-item label="始祖" :label-width="formLabelWidth">
            <el-input v-model="gptMessageDocumentInfoDto.ancestor" placeholder="请输入内容"/>
          </el-form-item>
          <el-form-item label="散居地" :label-width="formLabelWidth">
            <el-input v-model="gptMessageDocumentInfoDto.diaspora" placeholder="请输入内容"/>
          </el-form-item>
          <el-form-item label="堂号" :label-width="formLabelWidth">
            <el-input v-model="gptMessageDocumentInfoDto.hall" placeholder="请输入内容"/>
          </el-form-item>
        </template>

        <!-- 志书 -->
        <!--
         <template v-if="gptMessageDocumentInfoDto.documentType === 131">
          <el-form-item label="丛编项" :label-width="formLabelWidth">
            <el-input v-model="gptMessageDocumentInfoDto.seriesArea" placeholder="请输入内容"/>
          </el-form-item>
          <el-form-item label="志书类型" :label-width="formLabelWidth">
            <el-input
                v-model="gptMessageDocumentInfoDto.resourceType"
                placeholder="请输入内容"
            />
          </el-form-item>

          <el-form-item label="装订方式" :label-width="formLabelWidth">
            <el-input v-model="gptMessageDocumentInfoDto.bookBinding" placeholder="请输入内容"/>
          </el-form-item>

          <el-form-item label="升本" :label-width="formLabelWidth">
            <el-input v-model="gptMessageDocumentInfoDto.bookSize" placeholder="请输入内容"/>
          </el-form-item>

          <el-form-item label="印数" :label-width="formLabelWidth">
            <el-input v-model="gptMessageDocumentInfoDto.printNumber" placeholder="请输入内容"/>
          </el-form-item>

          <el-form-item label="字数" :label-width="formLabelWidth">
            <el-input v-model="gptMessageDocumentInfoDto.wordNumber" placeholder="请输入内容"/>
          </el-form-item>

          <el-form-item label="定价" :label-width="formLabelWidth">
            <el-input v-model="gptMessageDocumentInfoDto.price" placeholder="请输入内容"/>
          </el-form-item>

          <el-form-item label="数字加工来源格式" :label-width="formLabelWidth">
            <el-input
                v-model="gptMessageDocumentInfoDto.digitialSource"
                placeholder="请输入内容"
            />
          </el-form-item>

          <el-form-item label="数字化加工机构" :label-width="formLabelWidth">
            <el-input
                v-model="gptMessageDocumentInfoDto.digitialUnit"
                placeholder="请输入内容"
            />
          </el-form-item>

          <el-form-item
              label="数字化加工交付时间"
              :label-width="formLabelWidth"
          >
            <el-input v-model="gptMessageDocumentInfoDto.idadDate" placeholder="请输入内容"/>
          </el-form-item>

          <el-form-item
              label="数字化加工执行标准"
              :label-width="formLabelWidth"
          >
            <el-input
                v-model="gptMessageDocumentInfoDto.standardOfExecution"
                placeholder="请输入内容"
            />
          </el-form-item>

          <el-form-item label="来源方式" :label-width="formLabelWidth">
            <el-input v-model="gptMessageDocumentInfoDto.sourceType" placeholder="请输入内容"/>
          </el-form-item>

          <el-form-item label="提供方本地资源" :label-width="formLabelWidth">
            <el-input v-model="gptMessageDocumentInfoDto.localID" placeholder="请输入内容"/>
          </el-form-item>

          <el-form-item label="提供方名称" :label-width="formLabelWidth">
            <el-input v-model="gptMessageDocumentInfoDto.sourceFrom" placeholder="请输入内容"/>
          </el-form-item>

          <el-form-item
              label="提供方有无marc记录"
              :label-width="formLabelWidth"
          >
            <el-input v-model="gptMessageDocumentInfoDto.hasMarc" placeholder="请输入内容"/>
          </el-form-item>

          <el-form-item label="收藏单位代码" :label-width="formLabelWidth">
            <el-input
                v-model="gptMessageDocumentInfoDto.collectorCode"
                placeholder="请输入内容"
            />
          </el-form-item>

          <el-form-item label="收藏单位名称" :label-width="formLabelWidth">
            <el-input
                v-model="gptMessageDocumentInfoDto.collectorName"
                placeholder="请输入内容"
            />
          </el-form-item>

          <el-form-item label="典藏号" :label-width="formLabelWidth">
            <el-input
                v-model="gptMessageDocumentInfoDto.repositoryNumber"
                placeholder="请输入内容"
            />
          </el-form-item>

          <el-form-item label="排架区分号" :label-width="formLabelWidth">
            <el-input v-model="gptMessageDocumentInfoDto.shefCode" placeholder="请输入内容"/>
          </el-form-item>

          <el-form-item label="入藏日期" :label-width="formLabelWidth">
            <el-input v-model="gptMessageDocumentInfoDto.collectDate" placeholder="请输入内容"/>
          </el-form-item>

          <el-form-item label="完残程度" :label-width="formLabelWidth">
            <el-input
                v-model="gptMessageDocumentInfoDto.integrityLevel"
                placeholder="请输入内容"
            />
          </el-form-item>

          <el-form-item label="收藏人" :label-width="formLabelWidth">
            <el-input v-model="gptMessageDocumentInfoDto.storer" placeholder="请输入内容"/>
          </el-form-item>

          <el-form-item label="收藏历史" :label-width="formLabelWidth">
            <el-input v-model="gptMessageDocumentInfoDto.storeStory" placeholder="请输入内容"/>
          </el-form-item>

          <el-form-item label="整理研究人" :label-width="formLabelWidth">
            <el-input v-model="gptMessageDocumentInfoDto.researcher" placeholder="请输入内容"/>
          </el-form-item>

          <el-form-item label="整理研究历史" :label-width="formLabelWidth">
            <el-input
                v-model="gptMessageDocumentInfoDto.researchStory"
                placeholder="请输入内容"
            />
          </el-form-item>
        </template>
        -->

        <el-form-item label="格式" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.format" placeholder="请输入内容"/>
        </el-form-item>
        <el-form-item label="关联" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.relation" placeholder="请输入内容"/>
        </el-form-item>
        <el-form-item label="语种" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.language" placeholder="请输入内容"/>
        </el-form-item>
        <el-form-item label="时间范围" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.temporal" placeholder="请输入内容"/>
        </el-form-item>
        <el-form-item label="空间范围" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.spatial" placeholder="请输入内容"/>
        </el-form-item>
        <el-form-item label="版本" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.edition" placeholder="请输入内容"/>
        </el-form-item>
        <el-form-item label="适用对象" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.audience" placeholder="请输入内容"/>
        </el-form-item>
        <el-form-item label="ISBN" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.isbn" placeholder="请输入内容"/>
        </el-form-item>
        <el-form-item label="统一书号" :label-width="formLabelWidth">
          <el-input
              v-model="gptMessageDocumentInfoDto.unionBooksNumber"
              placeholder="请输入内容"
          />
        </el-form-item>
        <el-form-item label="CDOI" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.cdoi" placeholder="请输入内容"/>
        </el-form-item>
        <el-form-item label="发布地址" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.uri" placeholder="请输入内容"/>
        </el-form-item>
        <el-form-item label="数据提交单位" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.owner" placeholder="请输入内容"/>
        </el-form-item>
        <el-form-item label="所属任务年份" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.recordDate" placeholder="请输入内容"/>
        </el-form-item>

        <el-form-item label="旧系统BarCode" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.barCode" placeholder="请输入内容"/>
        </el-form-item>

        <el-form-item label="出版项" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.publisherInfo" placeholder="请输入内容"/>
        </el-form-item>

        <el-form-item label="摘要" :label-width="formLabelWidth">
          <el-input v-model="gptMessageDocumentInfoDto.abstract" placeholder="请输入内容"/>
        </el-form-item>
      </el-form>
    </div>
    <div class="tool">
      <el-button type="primary" plain @click="onclick"
      >保存
      </el-button
      >
    </div>
  </div>
</template>

<style scoped lang="scss">
  .metadata-container{
    width: 100%;
    height: 100%;
    .temp {
      width: 100%;
      overflow: auto;
      height: calc(100% - 100px);
    }

    .tool {
      height: 50px;
      border-top: 1px solid #eee;
      text-align: end;
      line-height: 50px;
    }
  }
</style>
